import { call, put, takeLatest } from 'redux-saga/effects'

import { PricingServicePromiseClient } from '../../proto/booking/v1/pricing_grpc_web_pb'
import * as pricingv1 from '../../proto/booking/v1/pricing_pb'

import {
  Actions,
  CREATE_REQ,
  DELETE_REQ,
  EDIT_REQ,
  GET_REQ,
  LIST_REQ,
} from '../../store/booking/pricing/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* list(client: PricingServicePromiseClient) {
  try {
    const req = new pricingv1.ListPricingsRequest()
    const resp: pricingv1.ListPricingsResponse = yield call(
      [client, client.listPricings],
      req,
      authMetadata(),
    )
    yield put(Actions.listResp(resp.getTotalCount(), resp.getPricingsList()))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listErr(err))
  }
}

export function* get(
  client: PricingServicePromiseClient,
  action: ReturnType<typeof Actions.getReq>,
) {
  try {
    const req = new pricingv1.GetPricingRequest()
    const { id } = action.payload
    req.setPricingId(id)
    const resp: pricingv1.GetPricingResponse = yield call(
      [client, client.getPricing],
      req,
      authMetadata(),
    )
    const pricing = resp.getPricing()
    if (!pricing) {
      throw new Error('missing pricing')
    }
    yield put(Actions.getResp(pricing))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.getErr(err))
  }
}

export function* create(
  client: PricingServicePromiseClient,
  action: ReturnType<typeof Actions.createReq>,
) {
  try {
    const { pricing } = action.payload
    const req = new pricingv1.CreatePricingRequest()
    req.setPricing(pricing)
    const resp: pricingv1.CreatePricingResponse = yield call(
      [client, client.createPricing],
      req,
      authMetadata(),
    )
    const createdPricing = resp.getPricing()
    if (!createdPricing) {
      throw new Error('missing pricing')
    }
    yield put(Actions.createResp(createdPricing))
    yield put(
      NotificationActions.send({
        key: `pricing-${createdPricing.getName()}`,
        kind: 'success',
        message: 'Pricing Created',
        description: 'The pricing was created successfully.',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createErr(err))
  }
}

export function* edit(
  client: PricingServicePromiseClient,
  action: ReturnType<typeof Actions.editReq>,
) {
  try {
    const { pricing } = action.payload
    const req = new pricingv1.EditPricingRequest()
    req.setPricing(pricing)
    const resp: pricingv1.EditPricingResponse = yield call(
      [client, client.editPricing],
      req,
      authMetadata(),
    )
    const editedPricing = resp.getPricing()
    if (!editedPricing) {
      throw new Error('missing pricing')
    }
    yield put(Actions.editResp(editedPricing))
    yield put(
      NotificationActions.send({
        key: `pricing-${editedPricing.getName()}`,
        kind: 'success',
        message: 'Pricing Updated',
        description: 'The pricing has been updated.',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editErr(err))
  }
}

export function* deletePricing(
  client: PricingServicePromiseClient,
  action: ReturnType<typeof Actions.deleteReq>,
) {
  try {
    const { pricingID } = action.payload
    const req = new pricingv1.DeletePricingRequest()
    req.setPricingId(pricingID)
    yield call([client, client.deletePricing], req, authMetadata())
    yield put(Actions.deleteResp(pricingID))
    yield put(
      NotificationActions.send({
        key: 'pricing-deleted',
        kind: 'success',
        message: 'Pricing Deleted',
        description: 'The pricing has been deleted.',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.deleteErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_REQ, list, clients.pricing)
  yield takeLatest(GET_REQ, get, clients.pricing)
  yield takeLatest(CREATE_REQ, create, clients.pricing)
  yield takeLatest(EDIT_REQ, edit, clients.pricing)
  yield takeLatest(DELETE_REQ, deletePricing, clients.pricing)
}
