import * as grpcWeb from 'grpc-web'
import { call, put, takeEvery } from 'redux-saga/effects'

import * as commonQuery from '../../proto/common/query_pb'
import { BranchServicePromiseClient } from '../../proto/iam/v1/branch_grpc_web_pb'
import * as branchv1 from '../../proto/iam/v1/branch_pb'

import {
  Actions,
  CREATE_REQ,
  DELETE_REQ,
  EDIT_REQ,
  GET_REQ,
  LIST_BRANCHES_REQ,
} from '../../store/iam/branch/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* listBranches(
  client: BranchServicePromiseClient,
  action: ReturnType<typeof Actions.listBranchesReq>,
) {
  try {
    const { skip, limit } = action.payload
    const req = new branchv1.ListBranchesRequest()

    if (limit !== 0 || skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(limit)
      pagination.setSkip(skip)
      req.setPagination(pagination)
    }

    const resp: branchv1.ListBranchesResponse = yield call(
      [client, client.listBranches],
      req,
      authMetadata(),
    )
    yield put(Actions.listBranchesResp(resp.getTotalCount(), resp.getBranchesList()))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listBranchesErr(err))
  }
}

export function* getBranch(
  client: BranchServicePromiseClient,
  action: ReturnType<typeof Actions.getBranchReq>,
) {
  try {
    const { id } = action.payload
    const req = new branchv1.GetBranchRequest()
    req.setBranchId(id)

    const resp: branchv1.GetBranchResponse = yield call(
      [client, client.getBranch],
      req,
      authMetadata(),
    )
    const branch = resp.getBranch()
    if (!branch) {
      throw new Error('missing branch')
    }
    yield put(Actions.getBranchResp(branch))
  } catch (err) {
    if ((err as any).code && (err as any).code === grpcWeb.StatusCode.NOT_FOUND) {
      yield put(Actions.getBranchResp(undefined))
    } else if (err instanceof Error) {
      yield put(Actions.getBranchErr(err))
    }
  }
}

export function* createBranch(
  client: BranchServicePromiseClient,
  action: ReturnType<typeof Actions.createBranchReq>,
) {
  try {
    const { branch } = action.payload
    const req = new branchv1.CreateBranchRequest()
    req.setBranch(branch)
    const resp: branchv1.CreateBranchResponse = yield call(
      [client, client.createBranch],
      req,
      authMetadata(),
    )
    const newBranch = resp.getBranch()
    if (!newBranch) {
      throw new Error('missing branch')
    }
    yield put(Actions.createBranchResp(newBranch))
    yield put(
      NotificationActions.send({
        key: `branch-${newBranch.getBranchId()}`,
        kind: 'success',
        message: 'Branch Created',
        description: 'Branch ' + newBranch.getName() + ' was created successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createBranchErr(err))
  }
}

export function* editBranch(
  client: BranchServicePromiseClient,
  action: ReturnType<typeof Actions.editBranchReq>,
) {
  try {
    const { branch } = action.payload
    const req = new branchv1.EditBranchRequest()
    req.setBranch(branch)
    const resp: branchv1.EditBranchResponse = yield call(
      [client, client.editBranch],
      req,
      authMetadata(),
    )
    const editBranch = resp.getBranch()
    if (!editBranch) {
      throw new Error('missing branch')
    }
    yield put(Actions.editBranchResp(editBranch))
    yield put(
      NotificationActions.send({
        key: `branch-${editBranch.getBranchId()}`,
        kind: 'success',
        message: 'Branch Updated',
        description: 'Branch ' + editBranch.getName() + ' was updated successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editBranchErr(err))
  }
}

export function* deleteBranch(
  client: BranchServicePromiseClient,
  action: ReturnType<typeof Actions.deleteBranchReq>,
) {
  try {
    const { id } = action.payload
    const req = new branchv1.DeleteBranchRequest()
    req.setBranchId(id)
    yield call([client, client.deleteBranch], req, authMetadata())

    yield put(
      NotificationActions.send({
        key: `branch-${id}`,
        kind: 'success',
        message: 'Branch Deleted',
        description: 'The branch was deleted successfully.',
        dismissAfter: 4500,
      }),
    )
    yield put(Actions.deleteBranchResp(id))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.deleteBranchErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeEvery(LIST_BRANCHES_REQ, listBranches, clients.branch)
  yield takeEvery(GET_REQ, getBranch, clients.branch)
  yield takeEvery(CREATE_REQ, createBranch, clients.branch)
  yield takeEvery(EDIT_REQ, editBranch, clients.branch)
  yield takeEvery(DELETE_REQ, deleteBranch, clients.branch)
}
