import { BookingServicePromiseClient } from 'proto/booking/v1/booking_grpc_web_pb'
import { BookingTemplateServicePromiseClient } from 'proto/booking/v1/bookingtemplate_grpc_web_pb'
import { DeviationServicePromiseClient } from 'proto/booking/v1/deviation_grpc_web_pb'
import { NominationServicePromiseClient } from 'proto/booking/v1/nomination_grpc_web_pb'
import { PricingServicePromiseClient } from 'proto/booking/v1/pricing_grpc_web_pb'
import { SupplierSvcPromiseClient } from 'proto/booking/v1/supplier_grpc_web_pb'
import { ContractServicePromiseClient } from 'proto/contract/v1/contract_grpc_web_pb'
import { AddressServicePromiseClient } from 'proto/crm/v1/address_grpc_web_pb'
import { CurrencyServicePromiseClient } from 'proto/currency/v1/currency_grpc_web_pb'
import { DocumentServicePromiseClient } from 'proto/document/v1/document_grpc_web_pb'
import { FilterServicePromiseClient } from 'proto/filtering/v1/filter_grpc_web_pb'
import { BranchServicePromiseClient } from 'proto/iam/v1/branch_grpc_web_pb'
import { OrganizationServicePromiseClient } from 'proto/iam/v1/organization_grpc_web_pb'
import { SignupServicePromiseClient } from 'proto/iam/v1/signup_grpc_web_pb'
import { UserServicePromiseClient } from 'proto/iam/v1/user_grpc_web_pb'
import { UserGroupServicePromiseClient } from 'proto/iam/v1/usergroup_grpc_web_pb'
import { ArticleTypeServicePromiseClient } from 'proto/invoicing/v1/articletype_grpc_web_pb'
import { CostServicePromiseClient } from 'proto/invoicing/v1/cost_grpc_web_pb'
import { InvoiceServicePromiseClient } from 'proto/invoicing/v1/invoice_grpc_web_pb'
import { ProviderServicePromiseClient } from 'proto/invoicing/v1/provider_grpc_web_pb'
import { MessageServicePromiseClient } from 'proto/messaging/v1/messaging_grpc_web_pb'
import { OrderServicePromiseClient } from 'proto/order/v1/order_grpc_web_pb'
import { ProductServicePromiseClient } from 'proto/order/v1/product_grpc_web_pb'
import { ShipmentServicePromiseClient } from 'proto/shipment/v1/shipment_grpc_web_pb'

export interface GRPCClients {
  articleType: ArticleTypeServicePromiseClient
  cost: CostServicePromiseClient
  booking: BookingServicePromiseClient
  bookingTemplate: BookingTemplateServicePromiseClient
  deviation: DeviationServicePromiseClient
  nomination: NominationServicePromiseClient
  pricing: PricingServicePromiseClient
  supplier: SupplierSvcPromiseClient
  contract: ContractServicePromiseClient
  address: AddressServicePromiseClient
  currency: CurrencyServicePromiseClient
  document: DocumentServicePromiseClient
  filter: FilterServicePromiseClient
  branch: BranchServicePromiseClient
  organization: OrganizationServicePromiseClient
  signup: SignupServicePromiseClient
  user: UserServicePromiseClient
  userGroup: UserGroupServicePromiseClient
  invoice: InvoiceServicePromiseClient
  provider: ProviderServicePromiseClient
  message: MessageServicePromiseClient
  order: OrderServicePromiseClient
  product: ProductServicePromiseClient
  shipment: ShipmentServicePromiseClient
}

export function grpcClients() {
  const clients: GRPCClients = {
    articleType: new ArticleTypeServicePromiseClient(''),
    cost: new CostServicePromiseClient(''),
    booking: new BookingServicePromiseClient(''),
    bookingTemplate: new BookingTemplateServicePromiseClient(''),
    deviation: new DeviationServicePromiseClient(''),
    nomination: new NominationServicePromiseClient(''),
    pricing: new PricingServicePromiseClient(''),
    supplier: new SupplierSvcPromiseClient(''),
    contract: new ContractServicePromiseClient(''),
    address: new AddressServicePromiseClient(''),
    currency: new CurrencyServicePromiseClient(''),
    document: new DocumentServicePromiseClient(''),
    filter: new FilterServicePromiseClient(''),
    branch: new BranchServicePromiseClient(''),
    organization: new OrganizationServicePromiseClient(''),
    signup: new SignupServicePromiseClient(''),
    user: new UserServicePromiseClient(''),
    userGroup: new UserGroupServicePromiseClient(''),
    invoice: new InvoiceServicePromiseClient(''),
    provider: new ProviderServicePromiseClient(''),
    message: new MessageServicePromiseClient(''),
    order: new OrderServicePromiseClient(''),
    product: new ProductServicePromiseClient(''),
    shipment: new ShipmentServicePromiseClient(''),
  }

  const enableGRPCWebDevTools =
    (process.env.NODE_ENV === 'development' && window && (window as any).__GRPCWEB_DEVTOOLS__) ||
    (() => {})
  Object.values(clients).forEach((client) => {
    enableGRPCWebDevTools([client])
  })

  return clients
}
