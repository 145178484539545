import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import * as commonQuery from '../../proto/common/query_pb'
import { ProductServicePromiseClient } from '../../proto/order/v1/product_grpc_web_pb'
import * as productv1 from '../../proto/order/v1/product_pb'

import {
  Actions,
  CREATE_REQ,
  GET_REQ,
  LIST_REQ,
  UPDATE_REQ,
} from '../../store/order/product/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* list(
  client: ProductServicePromiseClient,
  action: ReturnType<typeof Actions.listProductsReq>,
) {
  try {
    const { page } = action.payload
    const req = new productv1.ListProductsRequest()

    if (page.pagination.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(page.pagination.limit)
      pagination.setSkip(page.pagination.skip)
      req.setPagination(pagination)
    }

    const resp: productv1.ListProductsResponse = yield call(
      [client, client.listProducts],
      req,
      authMetadata(),
    )
    yield put(Actions.listProductsResp(resp.getTotalCount(), resp.getProductsList(), page))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listProductsErr(err))
  }
}

export function* get(
  client: ProductServicePromiseClient,
  action: ReturnType<typeof Actions.getProductReq>,
) {
  try {
    const { sku } = action.payload
    const req = new productv1.GetProductRequest()
    req.setSku(sku)
    const resp: productv1.GetProductResponse = yield call(
      [client, client.getProduct],
      req,
      authMetadata(),
    )
    const product = resp.getProduct()
    if (!product) {
      throw new Error('missing product')
    }
    yield put(Actions.getProductResp(product))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.getProductErr(err))
  }
}

export function* create(
  client: ProductServicePromiseClient,
  action: ReturnType<typeof Actions.createProductReq>,
) {
  try {
    const { product } = action.payload
    const req = new productv1.CreateProductRequest()
    req.setProduct(product)
    const resp: productv1.CreateProductResponse = yield call(
      [client, client.createProduct],
      req,
      authMetadata(),
    )
    const newProduct = resp.getProduct()
    if (!newProduct) {
      throw new Error('missing product')
    }
    yield put(Actions.createProductResp(newProduct))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createProductErr(err))
  }
}

export function* update(
  client: ProductServicePromiseClient,
  action: ReturnType<typeof Actions.updateProductReq>,
) {
  try {
    const { product } = action.payload
    const req = new productv1.UpdateProductRequest()
    req.setProduct(product)
    const resp: productv1.UpdateProductResponse = yield call(
      [client, client.updateProduct],
      req,
      authMetadata(),
    )
    const updatedProduct = resp.getProduct()
    if (!updatedProduct) {
      throw new Error('missing product')
    }
    yield put(Actions.updateProductResp(updatedProduct))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.updateProductErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_REQ, list, clients.product)
  yield takeLatest(GET_REQ, get, clients.product)
  yield takeEvery(CREATE_REQ, create, clients.product)
  yield takeEvery(UPDATE_REQ, update, clients.product)
}
