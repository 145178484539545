import { call, put, takeLatest } from 'redux-saga/effects'
import { fromContractProto, toContractProto } from 'shared/mappers/contract.mapper'

import { Pagination } from 'proto/common/query_pb'
import { ContractServicePromiseClient } from 'proto/contract/v1/contract_grpc_web_pb'
import {
  ContractFilter,
  ContractSorting,
  CreateContractRequest,
  CreateContractResponse,
  GetContractRequest,
  GetContractResponse,
  ListContractsRequest,
  ListContractsResponse,
  UpdateContractRequest,
  UpdateContractResponse,
} from 'proto/contract/v1/contract_pb'

import {
  CREATE_CONTRACT_REQ,
  ContractActions,
  GET_CONTRACT_REQ,
  LIST_CONTRACTS_REQ,
  UPDATE_CONTRACT_REQ,
} from 'store/contract/actions'
import { Actions as NotificationActions } from 'store/notification/actions'
import { Notif } from 'store/notification/types'

import { authMetadata } from 'helpers/auth'

import { GRPCClients } from '../clients'

export function* createContractRequest(
  client: ContractServicePromiseClient,
  action: ReturnType<typeof ContractActions.createContractReq>,
) {
  try {
    const rq: CreateContractRequest = new CreateContractRequest()
    rq.setContract(toContractProto(action.payload.contract))
    const resp: CreateContractResponse = yield call(
      [client, client.createContract],
      rq,
      authMetadata(),
    )
    const newContract = resp.getContract()
    if (newContract) {
      const mappedContract = fromContractProto(newContract)
      yield put(ContractActions.createContractResp(mappedContract))
      action.payload.callback(mappedContract)

      const notification: Notif = {
        key: `Contract-${newContract.getContractRef()}`,
        kind: 'success',
        message: 'Contract created',
        description: `Contract ${newContract.getContractRef()} has been created`,
        dismissAfter: 4500,
      }
      yield put(NotificationActions.send(notification))
    }
  } catch (err: any) {
    yield put(ContractActions.createContractErr(err))
  }
}

export function* editContractRequest(
  client: ContractServicePromiseClient,
  action: ReturnType<typeof ContractActions.updateContractReq>,
) {
  try {
    const rq = new UpdateContractRequest()
    rq.setContract(toContractProto(action.payload.contract))
    const resp: UpdateContractResponse = yield call(
      [client, client.updateContract],
      rq,
      authMetadata(),
    )

    const newContract = resp.getContract()
    if (newContract) {
      const mappedContract = fromContractProto(newContract)
      yield put(ContractActions.updateContractResp(mappedContract))
      const notification: Notif = {
        key: `Contract-${newContract.getContractRef()}`,
        kind: 'success',
        message: 'Contract was updated',
        description: `Contract ${newContract.getContractRef()} has been updated`,
        dismissAfter: 4500,
      }
      yield put(NotificationActions.send(notification))
    }
  } catch (err: any) {
    yield put(ContractActions.updateContractErr(err))
  }
}

export function* listContractsRequest(
  client: ContractServicePromiseClient,
  action: ReturnType<typeof ContractActions.listContractsReq>,
) {
  try {
    const { page } = action.payload
    const req = new ListContractsRequest()
    if (page.pagination.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new Pagination()
      pagination.setLimit(page.pagination.limit)
      pagination.setSkip(page.pagination.skip)
      req.setPagination(pagination)
    }
    if (page.filter) {
      const filter = new ContractFilter()
      if (page.filter.contractRef && page.filter.contractRef.length > 0) {
        filter.setContractRefList(page.filter.contractRef)
      }
      if (page.filter.organizationId && page.filter.organizationId.length > 0) {
        filter.setOrganizationIdList(page.filter.organizationId)
      }
      if (page.filter.status && page.filter.status.length > 0) {
        filter.setStatusList(page.filter.status)
      }
      if (page.filter.transportMode && page.filter.transportMode.length > 0) {
        filter.setTransportModeList(page.filter.transportMode)
      }
      if (page.filter.branchId && page.filter.branchId.length > 0) {
        filter.setBranchIdList(page.filter.branchId)
      }
      if (page.filter.senderCountry && page.filter.senderCountry.length > 0) {
        filter.setFromCountryIdList(page.filter.senderCountry)
      }
      if (page.filter.receiverCountry && page.filter.receiverCountry.length > 0) {
        filter.setToCountryIdList(page.filter.receiverCountry)
      }
      if (page.filter.benefitCalculation !== undefined) {
        filter.setBenefitCalculation(page.filter.benefitCalculation)
      }
      if (page.filter.salesRepresentative?.length && page.filter.salesRepresentative?.length > 0) {
        filter.setSalesRepresentativeList(page.filter.salesRepresentative)
      }
      req.setFilter(filter)
    }
    if (page.sorting) {
      const sorting = new ContractSorting()
      sorting.setField(page.sorting.getField())
      req.setSorting(sorting)
    }

    const resp: ListContractsResponse = yield call(
      [client, client.listContracts],
      req,
      authMetadata(),
    )
    const iContracts = resp.getContractsList().map((c) => fromContractProto(c))
    yield put(ContractActions.listContractsResp(resp.getCount(), iContracts, page))
  } catch (err: any) {
    yield put(ContractActions.listContractsErr(err))
  }
}

export function* getContractRequest(
  client: ContractServicePromiseClient,
  action: ReturnType<typeof ContractActions.getContractReq>,
) {
  try {
    const request = new GetContractRequest()
    request.setContractRef(action.payload.contractRef)
    const response: GetContractResponse = yield client.getContract(request, authMetadata())
    const responseContract = response.getContract()
    if (responseContract) {
      const contract = fromContractProto(responseContract)
      yield put(ContractActions.getContractResp(contract))
    } else {
      throw new Error(`Contract with referece ${action.payload.contractRef} was not found`)
    }
  } catch (err: any) {
    yield put(ContractActions.getContractErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_CONTRACTS_REQ, listContractsRequest, clients.contract)
  yield takeLatest(GET_CONTRACT_REQ, getContractRequest, clients.contract)
  yield takeLatest(CREATE_CONTRACT_REQ, createContractRequest, clients.contract)
  yield takeLatest(UPDATE_CONTRACT_REQ, editContractRequest, clients.contract)
}
