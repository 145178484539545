import { call, put, takeLatest } from 'redux-saga/effects'

import { BookingTemplateServicePromiseClient } from '../../proto/booking/v1/bookingtemplate_grpc_web_pb'
import * as bookingtemplatev1 from '../../proto/booking/v1/bookingtemplate_pb'
import * as commonQuery from '../../proto/common/query_pb'

import {
  Actions,
  CREATE_REQ,
  DELETE_REQ,
  GET_REQ,
  LIST_REQ,
} from '../../store/booking/bookingtemplate/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* list(
  client: BookingTemplateServicePromiseClient,
  action: ReturnType<typeof Actions.listBookingTemplatesReq>,
) {
  try {
    const { page } = action.payload
    const req = new bookingtemplatev1.ListBookingTemplatesRequest()

    if (page.pagination.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(page.pagination.limit)
      pagination.setSkip(page.pagination.skip)
      req.setPagination(pagination)
    }
    if (page.sorting) {
      const sort = new bookingtemplatev1.TemplateSorting()
      sort.setField(page.sorting.getField())
      sort.setOrdering(page.sorting.getOrdering())
      req.setSorting(sort)
    }

    const resp: bookingtemplatev1.ListBookingTemplatesResponse = yield call(
      [client, client.listBookingTemplates],
      req,
      authMetadata(),
    )
    yield put(
      Actions.listBookingTemplatesResp(resp.getTotalCount(), resp.getBookingTemplatesList(), page),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listBookingTemplatesErr(err))
  }
}

export function* get(
  client: BookingTemplateServicePromiseClient,
  action: ReturnType<typeof Actions.getBookingTemplateReq>,
) {
  try {
    const req = new bookingtemplatev1.GetBookingTemplateRequest()
    const { id } = action.payload
    req.setBookingTemplateId(id)
    const resp: bookingtemplatev1.GetBookingTemplateResponse = yield call(
      [client, client.getBookingTemplate],
      req,
      authMetadata(),
    )
    const bookingTemplate = resp.getBookingTemplate()
    if (!bookingTemplate) {
      throw new Error('missing booking template')
    }
    yield put(Actions.getBookingTemplateResp(bookingTemplate))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.getBookingTemplateErr(err))
  }
}

export function* create(
  client: BookingTemplateServicePromiseClient,
  action: ReturnType<typeof Actions.createBookingTemplateReq>,
) {
  try {
    const { bookingTemplate, page } = action.payload
    const req = new bookingtemplatev1.CreateBookingTemplateRequest()
    req.setBookingTemplate(bookingTemplate)
    const resp: bookingtemplatev1.CreateBookingTemplateResponse = yield call(
      [client, client.createBookingTemplate],
      req,
      authMetadata(),
    )
    const newBookingTemplate = resp.getBookingTemplate()
    if (!newBookingTemplate) {
      throw new Error('missing booking template')
    }
    yield put(Actions.createBookingTemplateResp(newBookingTemplate, page))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createBookingTemplateErr(err))
  }
}

export function* del(
  client: BookingTemplateServicePromiseClient,
  action: ReturnType<typeof Actions.deleteBookingTemplateReq>,
) {
  try {
    const { id } = action.payload
    const req = new bookingtemplatev1.DeleteBookingTemplateRequest()
    req.setBookingTemplateId(id)
    yield call([client, client.deleteBookingTemplate], req, authMetadata())
    yield put(
      NotificationActions.send({
        key: `template-${id}`,
        kind: 'success',
        message: 'Template Deleted',
        description: 'The template was deleted successfully.',
        dismissAfter: 4500,
      }),
    )
    yield put(Actions.deleteBookingTemplateResp(id))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.deleteBookingTemplateErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_REQ, list, clients.bookingTemplate)
  yield takeLatest(GET_REQ, get, clients.bookingTemplate)
  yield takeLatest(CREATE_REQ, create, clients.bookingTemplate)
  yield takeLatest(DELETE_REQ, del, clients.bookingTemplate)
}
