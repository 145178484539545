import { call, put, select, takeEvery } from 'redux-saga/effects'

import { Owner } from 'proto/common/owner_pb'
import { DocumentServicePromiseClient } from 'proto/document/v1/document_grpc_web_pb'
import * as documentv1 from 'proto/document/v1/document_pb'
import { User } from 'proto/iam/v1/user_pb'

import {
  Actions,
  DELETE_REQUEST,
  GENERATE_BOOKING_DOCUMENT_REQUEST,
  GENERATE_SHIPMENT_DOCUMENT_REQUEST,
  LIST_REQUEST,
  UPLOAD_FILE,
} from 'store/document/actions'
import { getCurrentUser } from 'store/iam/user/reducer'
import { Actions as NotificationActions } from 'store/notification/actions'
import { Actions as StoryActions } from 'store/story/actions'

import { authMetadata } from 'helpers/auth'
import { haveRole } from 'helpers/user'

import { GRPCClients } from '../clients'

export function* list(
  client: DocumentServicePromiseClient,
  action: ReturnType<typeof Actions.list>,
) {
  try {
    const { page } = action.payload
    let currentUser = new User()

    currentUser = yield select(getCurrentUser)
    if (!currentUser.getUserId()) {
      return
    }

    const isTransporter = haveRole(User.Role.TRANSPORTER, currentUser)
    const req = new documentv1.ListDocumentsRequest()

    if (page.filter) {
      const filter = new documentv1.DocumentFilter()
      if (page.filter.userID && page.filter.userID.length > 0) {
        filter.setUserIdList(page.filter.userID)
      }
      if (page.filter.organizationID && page.filter.organizationID.length > 0 && !isTransporter) {
        filter.setOrganizationIdList(page.filter.organizationID)
      }
      if (page.filter.userGroupID && page.filter.userGroupID.length > 0 && !isTransporter) {
        filter.setUserGroupIdList(page.filter.userGroupID)
      }
      if (page.filter.orderRef && page.filter.orderRef.length > 0) {
        filter.setOrderRefList(page.filter.orderRef)
      }
      if (page.filter.bookingRef && page.filter.bookingRef.length > 0) {
        filter.setBookingRefList(page.filter.bookingRef)
      }
      if (page.filter.shipmentRef && page.filter.shipmentRef.length > 0) {
        filter.setShipmentRefList(page.filter.shipmentRef)
      }
      if (page.filter.organizationRef && page.filter.organizationRef.length > 0) {
        filter.setOrganizationRefList(page.filter.organizationRef)
      }
      if (page.filter.usage && page.filter.usage.length > 0) {
        filter.setUsagesList(page.filter.usage)
      }
      if (page.filter.deviationId && page.filter.deviationId.length > 0) {
        filter.setDeviationIdList(page.filter.deviationId)
      }
      if (page.filter.invoiceNo && page.filter.invoiceNo.length > 0) {
        filter.setInvoiceNoList(page.filter.invoiceNo)
      }
      req.setFilter(filter)
    }

    const resp: documentv1.ListDocumentsResponse = yield call(
      [client, client.listDocuments],
      req,
      authMetadata(),
    )
    const documents = resp.getDocumentsList()
    const count = resp.getCount()
    yield put(Actions.listResp(documents, count))
    if (count > 0) {
      yield put(StoryActions.updateStoryDocuments(count))
    }
  } catch (err: any) {
    yield put(Actions.listErr(err))
  }
}

export function* deleteDocument(
  client: DocumentServicePromiseClient,
  action: ReturnType<typeof Actions.delete>,
) {
  try {
    const { documentID } = action.payload
    const req = new documentv1.DeleteDocumentRequest()
    req.setDocumentId(documentID)
    const resp: documentv1.DeleteDocumentResponse = yield call(
      [client, client.deleteDocument],
      req,
      authMetadata(),
    )
    const respDocumentID = resp.getDocumentId()
    yield put(Actions.deleteResp(respDocumentID))
    yield put(
      NotificationActions.send({
        key: `document-deleted-${documentID}`,
        kind: 'success',
        message: 'Document deleted',
        description: `The document has been deleted.`,
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.deleteErr(err))
  }
}

export function* uploadFile(
  client: DocumentServicePromiseClient,
  action: ReturnType<typeof Actions.uploadFile>,
) {
  try {
    const {
      file,
      note,
      orderRef,
      bookingRef,
      shipmentRef,
      organizationRef,
      usage,
      deviationId,
      relatedOwner,
      namespace,
      isPrivate,
    } = action.payload
    const filename = file.name
    const formatedFileName = filename.replace(/[^- a-zA-Z0-9_/.]/g, '')
    // Set to 6, to ensure filename isn't empty
    if (formatedFileName.length < 6) {
      throw new Error('Formated name to short')
    }
    const createLinkReq = new documentv1.CreateDocumentUploadLinkRequest()
    let fileType = file.type

    if (fileType === '') {
      if (file.name.endsWith('.msg') && isPrivate) {
        fileType = 'application/vnd.ms-outlook'
      } else {
        throw new Error('Could not get file type')
      }
    }

    createLinkReq.setFilename(formatedFileName)
    createLinkReq.setMimeType(fileType)
    createLinkReq.setOrganizationNamespace(namespace)
    const createLinkResp: documentv1.CreateDocumentUploadLinkResponse = yield call(
      [client, client.createDocumentUploadLink],
      createLinkReq,
      authMetadata(),
    )
    const uploadLocation = createLinkResp.getUploadLocation()
    if (!uploadLocation) {
      throw new Error('Could not retrieve upload location from server')
    }

    const uploadSuccess: boolean = yield call(
      performGCloudUpload,
      file,
      decodeURI(uploadLocation.getUploadLocation()),
    )

    if (!uploadSuccess) {
      throw new Error('Failed to upload file to google cloud')
    }
    const documentName = uploadLocation.getDocumentName()
    const gObjectMeta = new documentv1.GCloudObjectMeta()
    gObjectMeta.setName(documentName)
    const createRequest = new documentv1.CreateDocumentRequest()
    const documentMetaData = new documentv1.DocumentMetaData()
    const owner = new Owner()
    owner.setUserId(relatedOwner.getUserId())
    owner.setOrganizationId(relatedOwner.getOrganizationId())
    owner.setUserGroupId(relatedOwner.getUserGroupId())
    owner.setBranchId(relatedOwner.getBranchId())
    documentMetaData.setShipmentRef(shipmentRef)
    documentMetaData.setOrderRef(orderRef)
    documentMetaData.setBookingRef(bookingRef)
    documentMetaData.setOrganizationRef(organizationRef)
    documentMetaData.setUsage(usage)
    documentMetaData.setDeviationId(deviationId)
    documentMetaData.setNote(note)
    documentMetaData.setTitle(formatedFileName)
    documentMetaData.setLocationType(documentv1.DocumentMetaData.LocationType.GCLOUD)
    documentMetaData.setGcloudObjectMeta(gObjectMeta)
    documentMetaData.setOwner(owner)
    documentMetaData.setUserUploaded(true)
    createRequest.setMetaData(documentMetaData)

    const createResponse: documentv1.CreateDocumentResponse = yield call(
      [client, client.createDocument],
      createRequest,
      authMetadata(),
    )
    const result = createResponse.getDocument()
    if (!result) {
      throw new Error('Response contained no document')
    }
    yield put(Actions.uploadFileSuccess(result))
    yield put(StoryActions.updateStoryDocuments())
  } catch (err: any) {
    yield put(Actions.uploadFileError(err))
  }
}

export function* generateBookingDocument(
  client: DocumentServicePromiseClient,
  action: ReturnType<typeof Actions.generateBookingDocument>,
) {
  try {
    const { booking, usage, customData } = action.payload

    const req = new documentv1.GenerateBookingDocumentRequest()
    req.setBooking(booking)
    req.setUsage(usage)
    req.setCustomData(customData)
    const resp: documentv1.GenerateBookingDocumentResponse = yield call(
      [client, client.generateBookingDocument],
      req,
      authMetadata(),
    )
    const result = resp.getDocument()
    if (!result) {
      throw new Error('Response contained no document')
    }
    yield put(Actions.generateBookingDocumentResp(result))
    yield put(
      NotificationActions.send({
        key: 'document-created',
        kind: 'success',
        message: 'Document Created',
        description: 'The document has been created.',
        dismissAfter: 4500,
      }),
    )
    yield put(StoryActions.updateStoryDocuments())
  } catch (err: any) {
    yield put(Actions.generateBookingDocumentErr(err))
  }
}

export function* generateShipmentDocument(
  client: DocumentServicePromiseClient,
  action: ReturnType<typeof Actions.generateShipmentDocument>,
) {
  try {
    const { shipment, usage, instructions } = action.payload

    const req = new documentv1.GenerateShipmentDocumentRequest()
    req.setShipment(shipment)
    req.setUsage(usage)
    req.setInstructionList(instructions)
    const resp: documentv1.GenerateShipmentDocumentResponse = yield call(
      [client, client.generateShipmentDocument],
      req,
      authMetadata(),
    )

    const result = resp.getDocument()
    if (!result) {
      throw new Error('Response contained no document')
    }
    yield put(Actions.generateShipmentDocumentResp(result))
    yield put(
      NotificationActions.send({
        key: 'document-created',
        kind: 'success',
        message: 'Document Created',
        description: 'The document has been created.',
        dismissAfter: 4500,
      }),
    )
    yield put(StoryActions.updateStoryDocuments())
  } catch (err: any) {
    yield put(Actions.generateShipmentDocumentErr(err))
  }
}

async function performGCloudUpload(file: File, location: string): Promise<boolean> {
  const response = await fetch(location, {
    method: 'PUT',
    body: file,
    mode: 'cors',
    credentials: 'omit',
    referrer: 'no-referrer',
  })
  if (!response.ok) {
    throw new Error(`Upload failed with: { Code: ${response.status}, Text: ${response.statusText}}`)
  }
  return true
}

export default function* sagas(clients: GRPCClients) {
  yield takeEvery(LIST_REQUEST, list, clients.document)
  yield takeEvery(UPLOAD_FILE, uploadFile, clients.document)
  yield takeEvery(DELETE_REQUEST, deleteDocument, clients.document)
  yield takeEvery(GENERATE_BOOKING_DOCUMENT_REQUEST, generateBookingDocument, clients.document)
  yield takeEvery(GENERATE_SHIPMENT_DOCUMENT_REQUEST, generateShipmentDocument, clients.document)
}
