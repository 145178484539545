import * as grpcWeb from 'grpc-web'
import { call, getContext, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { ContextItems, CustomContext } from 'sagas/context'

import * as commonQuery from '../../proto/common/query_pb'
import { ShipmentServicePromiseClient } from '../../proto/shipment/v1/shipment_grpc_web_pb'
import * as shipmentv1 from '../../proto/shipment/v1/shipment_pb'
import { Cost } from 'proto/invoicing/v1/cost_pb'

import { Actions as NotificationActions } from '../../store/notification/actions'
import {
  ADD_BOOKINGS_TO_SHIPMENTS_REQ,
  Actions,
  CREATE_REQ,
  CREATE_SHIPMENT_COST_REQ,
  GET_REQ,
  GET_STATS_REQ,
  LIST_REQ,
  LIST_SPLIT_COST_REF_REQ,
  REMOVE_BOOKINGS_FROM_SHIPMENTS_REQ,
  SET_BOOKING_ORDER_SHIPMENT_REQ,
  UPDATE_INTERNAL_NOTE_REQ,
  UPDATE_REQ,
  UPDATE_SHIPMENT_COST_REQ,
  UPDATE_SHIPMENT_COST_STATUS_REQ,
} from '../../store/shipment/shipment/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* list(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.listShipmentsReq>,
) {
  try {
    const { page } = action.payload
    const req = new shipmentv1.ListShipmentsRequest()

    if (page.pagination.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(page.pagination.limit)
      pagination.setSkip(page.pagination.skip)
      req.setPagination(pagination)
    }

    if (page.filter) {
      const filter = new shipmentv1.ShipmentFilter()
      if (page.filter.search && page.filter.search !== '') {
        filter.setSearch(page.filter.search)
      }
      if (page.filter.transportOperator && page.filter.transportOperator.length > 0) {
        filter.setTransportOperatorUserIdList(page.filter.transportOperator)
      }
      req.setFilter(filter)
    }

    if (page.sorting) {
      const sorting = new shipmentv1.ShipmentSorting()
      sorting.setField(page.sorting.getField())
      sorting.setOrdering(page.sorting.getOrdering())
      req.setSorting(sorting)
    }

    const resp: shipmentv1.ListShipmentsResponse = yield call(
      [client, client.listShipments],
      req,
      authMetadata(),
    )
    yield put(Actions.listShipmentsResp(resp.getTotalCount(), resp.getShipmentsList(), page))
  } catch (err: any) {
    yield put(Actions.listShipmentsErr(err))
  }
}

export function* get(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.getShipmentReq>,
) {
  try {
    const { ref, fetchLinkedItems } = action.payload
    const req = new shipmentv1.GetShipmentRequest()
    req.setShipmentRef(ref)
    const resp: shipmentv1.GetShipmentResponse = yield call(
      [client, client.getShipment],
      req,
      authMetadata(),
    )

    const shipment = resp.getShipment()
    if (!shipment) {
      throw new Error('missing shipment')
    }

    if (fetchLinkedItems) {
      // NOTE: Not fetching anything linked for now.
      // Could be used for fetching the bookings etc.
    }

    yield put(Actions.getShipmentResp(shipment))
  } catch (err: any) {
    if (err.code && err.code === grpcWeb.StatusCode.NOT_FOUND) {
      yield put(Actions.getShipmentResp(undefined))
    } else {
      yield put(Actions.getShipmentErr(err))
    }
  }
}

export function* getStats(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.getShipmentStatsReq>,
) {
  try {
    const { query } = action.payload
    const req = new shipmentv1.GetShipmentStatsRequest()
    req.setQuery(query)
    const resp: shipmentv1.GetShipmentStatsResponse = yield call(
      [client, client.getShipmentStats],
      req,
      authMetadata(),
    )
    const stats = resp.getStats()
    if (!stats) {
      throw new Error('missing stats')
    }

    yield put(Actions.getShipmentStatsResp(stats))
  } catch (err: any) {
    yield put(Actions.getShipmentStatsErr(err))
  }
}

export function* addBookingsToShipment(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.addBookingsToShipmentReq>,
) {
  try {
    const { bookingRefs, shipment } = action.payload

    const req = new shipmentv1.AddBookingToShipmentRequest()
    req.setBookingRefsList(bookingRefs)
    req.setShipment(shipment)

    const resp: shipmentv1.AddBookingToShipmentResponse = yield call(
      [client, client.addBookingToShipment],
      req,
      authMetadata(),
    )

    const result = resp.getShipment()
    if (!result) {
      throw new Error('missing shipment')
    }

    yield put(Actions.addBookingsToShipmentResp(result))

    yield put(
      NotificationActions.send({
        key: `shipment-${result.getShipmentRef()}`,
        kind: 'success',
        message: 'Bookings succefully added to shipment',
        description: 'Bookings succefully added',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.addBookingsToShipmentErr(err))
  }
}

export function* removeBookingsFromShipment(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.removeBookingsFromShipmentReq>,
) {
  try {
    const { bookingRefs, shipment } = action.payload

    const req = new shipmentv1.RemoveBookingInShipmentRequest()
    req.setBookingRefsList(bookingRefs)
    req.setShipment(shipment)

    const resp: shipmentv1.RemoveBookingInShipmentResponse = yield call(
      [client, client.removeBookingInShipment],
      req,
      authMetadata(),
    )

    const result = resp.getShipment()
    if (!result) {
      throw new Error('missing shipment')
    }

    yield put(Actions.removeBookingsFromShipmentResp(result))

    yield put(
      NotificationActions.send({
        key: `shipment-${result.getShipmentRef()}`,
        kind: 'success',
        message: 'Bookings succefully removed',
        description: 'Bookings succefully removed from shipment',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.removeBookingsFromShipmentErr(err))
  }
}

export function* create(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.createShipmentReq>,
) {
  try {
    const { shipment } = action.payload
    const req = new shipmentv1.CreateShipmentRequest()
    req.setShipment(shipment)
    const resp: shipmentv1.CreateShipmentResponse = yield call(
      [client, client.createShipment],
      req,
      authMetadata(),
    )
    const newShipment = resp.getShipment()
    if (!newShipment) {
      throw new Error('missing shipment')
    }

    yield put(Actions.createShipmentResp(newShipment))

    yield put(
      NotificationActions.send({
        key: `shipment-${newShipment.getShipmentRef()}`,
        kind: 'success',
        message: 'Shipment created',
        description: 'The shipment has been created.',
        dismissAfter: 4500,
      }),
    )
    const { context }: CustomContext = yield getContext(ContextItems.customContext)
    context.navigate(`/shipments/${newShipment.getShipmentRef()}`)
  } catch (err: any) {
    yield put(Actions.createShipmentErr(err))
  }
}

export function* update(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.updateShipmentReq>,
) {
  try {
    const { shipment } = action.payload
    const req = new shipmentv1.UpdateShipmentRequest()
    req.setShipment(shipment)
    const resp: shipmentv1.UpdateShipmentResponse = yield call(
      [client, client.updateShipment],
      req,
      authMetadata(),
    )
    const updatedShipment = resp.getShipment()
    if (!updatedShipment) {
      throw new Error('missing shipment')
    }

    yield put(Actions.updateShipmentResp(updatedShipment))

    yield put(
      NotificationActions.send({
        key: `shipment-${updatedShipment.getShipmentRef()}`,
        kind: 'success',
        message: 'Shipment updated',
        description: 'The shipment has been updated.',
        dismissAfter: 4500,
      }),
    )
    const { context }: CustomContext = yield getContext(ContextItems.customContext)
    context.navigate(`/shipments/${updatedShipment.getShipmentRef()}`)
  } catch (err: any) {
    yield put(Actions.updateShipmentErr(err))
  }
}

export function* createShipmentCost(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.createShipmentCostReq>,
) {
  try {
    const { shipmentRef, bookingRefs, amount, currency, costType, supplierId, comment } =
      action.payload

    const req = new shipmentv1.CreateShipmentCostRequest()
    req.setShipmentRef(shipmentRef)
    req.setBookingRefsList(bookingRefs)
    req.setAmount(amount)
    req.setCurrency(currency)
    req.setCostType(costType as unknown as Cost.Type)
    req.setSupplierId(supplierId)
    req.setComment(comment)

    const resp: shipmentv1.CreateShipmentCostResponse = yield call(
      [client, client.createShipmentCost],
      req,
      authMetadata(),
    )

    const splitCostRef = resp.getShipmentCost()
    if (!splitCostRef) {
      throw new Error('missing shipment cost')
    }

    yield put(Actions.createShipmentCostResp(splitCostRef as shipmentv1.SplitCostReference))

    yield put(
      NotificationActions.send({
        key: `shipment-${shipmentRef}`,
        kind: 'success',
        message: 'Shipment Costs created',
        description:
          'Cost were created in the selected booking based on bookings chargeable weight.',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.createShipmentCostErr(err))
  }
}

export function* listSplitCostRef(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.listSplitCostRefReq>,
) {
  try {
    const { shipmentId } = action.payload
    const req = new shipmentv1.ListSplitCostReferenceRequest()
    req.setShipmentId(shipmentId)
    const resp: shipmentv1.ListSplitCostReferenceResponse = yield call(
      [client, client.listSplitCostReference],
      req,
      authMetadata(),
    )

    const costRef = resp.getSplitCostReferencesList()
    if (!costRef) {
      throw new Error('missing split cost reference')
    }
    yield put(Actions.listSplitCostRefResp(costRef))
  } catch (err: any) {
    yield put(Actions.listSplitCostRefErr(err))
  }
}

export function* setBookingOrderInShipment(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.setBookingOrderInShipmentReq>,
) {
  try {
    const { bookingsRefs, shipmentRef } = action.payload
    const req = new shipmentv1.UpdateBookingOrderInShipmentRequest()
    req.setBookingRefsList(bookingsRefs)
    req.setShipmentRef(shipmentRef)

    const resp: shipmentv1.UpdateBookingOrderInShipmentResponse = yield call(
      [client, client.updateBookingOrderInShipment],
      req,
      authMetadata(),
    )

    const shipment = resp.getShipment()
    if (!shipment) {
      throw new Error('missing shipment')
    }

    yield put(Actions.setBookingOrderInShipmentResp(shipment))

    yield put(
      NotificationActions.send({
        key: `shipment-${shipmentRef}`,
        kind: 'success',
        message: 'Order changed',
        description: 'The order of the bookings has been changed.',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.setBookingOrderInShipmentErr(err))
  }
}

export function* updateInternalNote(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.updateInternalNoteReq>,
) {
  try {
    const { shipment } = action.payload
    const req = new shipmentv1.UpdateShipmentInternalNoteRequest()

    req.setShipmentRef(shipment.getShipmentRef())
    req.setInternalNote(shipment.getInternalNote())
    req.setInternalNoteRemindAt(shipment.getInternalNoteRemindAt())

    const resp: shipmentv1.UpdateShipmentInternalNoteResponse = yield call(
      [client, client.updateShipmentInternalNote],
      req,
      authMetadata(),
    )
    const updatedShipment = resp.getShipment()
    if (!updatedShipment) {
      throw new Error('missing shipment')
    }
    yield put(Actions.updateInternalNoteResp(updatedShipment))
    yield put(
      NotificationActions.send({
        key: `shipment-${updatedShipment.getShipmentRef()}`,
        kind: 'success',
        message: 'Shipment Note Edited',
        description: 'The shipment note has been edited successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.updateInternalNoteErr(err))
  }
}

export function* updateShipmentCost(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.updateShipmentCostReq>,
) {
  try {
    const { cost } = action.payload
    const resp: shipmentv1.UpdateShipmentCostResponse = yield call(
      [client, client.updateShipmentCost],
      cost,
      authMetadata(),
    )

    yield put(Actions.updateShipmentCostResp(resp))
  } catch (err: any) {
    yield put(Actions.updateShipmentCostErr(err))
  }
}

export function* updateShipmentCostStatus(
  client: ShipmentServicePromiseClient,
  action: ReturnType<typeof Actions.updateShipmentCostStatusReq>,
) {
  try {
    const { splitCostRefId, status, comment } = action.payload

    const req = new shipmentv1.UpdateShipmentCostStatusRequest()
    req.setSplitCostReferenceId(splitCostRefId)
    req.setStatus(status as unknown as Cost.Status)
    req.setComment(comment)

    const resp: shipmentv1.UpdateShipmentCostStatusResponse = yield call(
      [client, client.updateShipmentCostStatus],
      req,
      authMetadata(),
    )
    const shipmentCost = resp.getShipmentCost()
    if (shipmentCost) {
      yield put(
        NotificationActions.send({
          key: `cost-${shipmentCost.getSplitCostReferenceId()}`,
          kind: 'success',
          message: 'Cost Updated',
          description: `Cost status was changed successfully`,
          dismissAfter: 4500,
        }),
      )
    } else {
      throw new Error('Response contained no cost')
    }
    yield put(Actions.updateShipmentCostStatusResp(shipmentCost))
  } catch (err: any) {
    yield put(Actions.updateShipmentCostStatusErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_REQ, list, clients.shipment)
  yield takeLatest(GET_REQ, get, clients.shipment)
  yield takeLatest(GET_STATS_REQ, getStats, clients.shipment)
  yield takeEvery(CREATE_REQ, create, clients.shipment)
  yield takeEvery(UPDATE_REQ, update, clients.shipment)
  yield takeLatest(CREATE_SHIPMENT_COST_REQ, createShipmentCost, clients.shipment)
  yield takeLatest(LIST_SPLIT_COST_REF_REQ, listSplitCostRef, clients.shipment)
  yield takeLatest(SET_BOOKING_ORDER_SHIPMENT_REQ, setBookingOrderInShipment, clients.shipment)
  yield takeLatest(UPDATE_INTERNAL_NOTE_REQ, updateInternalNote, clients.shipment)
  yield takeEvery(REMOVE_BOOKINGS_FROM_SHIPMENTS_REQ, removeBookingsFromShipment, clients.shipment)
  yield takeLatest(ADD_BOOKINGS_TO_SHIPMENTS_REQ, addBookingsToShipment, clients.shipment)
  yield takeLatest(UPDATE_SHIPMENT_COST_REQ, updateShipmentCost, clients.shipment)
  yield takeEvery(UPDATE_SHIPMENT_COST_STATUS_REQ, updateShipmentCostStatus, clients.shipment)
}
