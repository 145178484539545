import * as Sentry from '@sentry/browser'
import { all, call, select, spawn, takeEvery } from 'redux-saga/effects'

import story from './story/story'

import articletype from './invoicing/articletype'

import auth from './auth'
import booking from './booking/booking'
import bookingTemplate from './booking/bookingtemplate'
import deviation from './booking/deviation'
import nomination from './booking/nomination'
import pricing from './booking/pricing'
import supplier from './booking/supplier'
import { grpcClients } from './clients'
import contract from './contracts/contract'
import address from './crm/address'
import currency from './currency/currency'
import document from './document/documents'
import filter from './filter/filter'
import branch from './iam/branch'
import organization from './iam/organization'
import signup from './iam/signup'
import user from './iam/user'
import usergroup from './iam/usergroup'
import cost from './invoicing/cost'
import invoice from './invoicing/invoice'
import provider from './invoicing/provider'
import message from './message/message'
import notification from './notification'
import order from './order/order'
import product from './order/product'
import shipment from './shipment/shipment'
import surcharge from './surcharge/surcharge'
import config from './ui/config'
import profitabilityView from './ui/profitabilityView'

// Convert any store errors to real errors, in turn handled by the root saga.
function* storeErrors() {
  yield takeEvery(
    (action: any) => action && action.payload && action.payload.err,
    function* (action: any) {
      yield select()
      const { err } = action.payload
      throw err
    },
  )
}

export default function* rootSaga() {
  const sagas = [
    address,
    booking,
    bookingTemplate,
    currency,
    supplier,
    nomination,
    deviation,
    document,
    filter,
    user,
    usergroup,
    organization,
    message,
    notification,
    order,
    product,
    pricing,
    shipment,
    articletype,
    cost,
    invoice,
    provider,
    config,
    profitabilityView,
    signup,
    storeErrors,
    story,
    branch,
    contract,
    surcharge,
    auth, // Start the auth last.
  ]

  const clients = grpcClients()

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        // Restart any crashing sagas.
        while (true) {
          try {
            yield call(saga, clients)
            break
          } catch (err) {
            // Log all saga erros.
            console.error(err)
            // Also send to sentry.io.
            Sentry.captureException(err)
          }
        }
      }),
    ),
  )
}
