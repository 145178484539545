import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { DeviationServicePromiseClient } from '../../proto/booking/v1/deviation_grpc_web_pb'
import * as deviationv1 from '../../proto/booking/v1/deviation_pb'

import {
  Actions,
  CREATE_REQ,
  DELETE_REQ,
  EDIT_REQ,
  LIST_REQ,
} from '../../store/booking/deviation/actions'
import { Actions as DocumentActions } from '../../store/document/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* list(
  client: DeviationServicePromiseClient,
  action: ReturnType<typeof Actions.listReq>,
) {
  try {
    const { bookingRef } = action.payload
    const req = new deviationv1.ListDeviationsRequest()
    req.setBookingRef(bookingRef)
    const resp: deviationv1.ListDeviationsResponse = yield call(
      [client, client.listDeviations],
      req,
      authMetadata(),
    )
    const deviations = resp.getDeviationsList()
    yield put(Actions.listResp(deviations, bookingRef))

    // Fetch deviation documents
    const deviationIds = resp.getDeviationsList().map((d) => d.getDeviationId())
    yield put(
      DocumentActions.list({ filter: { bookingRef: [bookingRef], deviationId: deviationIds } }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listErr(err))
  }
}

export function* create(
  client: DeviationServicePromiseClient,
  action: ReturnType<typeof Actions.createReq>,
) {
  try {
    const { deviation } = action.payload
    const req = new deviationv1.CreateDeviationRequest()
    req.setDeviation(deviation)
    const resp: deviationv1.CreateDeviationResponse = yield call(
      [client, client.createDeviation],
      req,
      authMetadata(),
    )
    const createDeviation = resp.getDeviation()
    if (!createDeviation) {
      throw new Error('missing deviation')
    }
    yield put(Actions.editResp(createDeviation))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editErr(err))
  }
}

export function* edit(
  client: DeviationServicePromiseClient,
  action: ReturnType<typeof Actions.editReq>,
) {
  try {
    const { deviation } = action.payload
    const req = new deviationv1.EditDeviationRequest()
    req.setDeviation(deviation)
    const resp: deviationv1.EditDeviationResponse = yield call(
      [client, client.editDeviation],
      req,
      authMetadata(),
    )
    const editedDeviation = resp.getDeviation()
    if (!editedDeviation) {
      throw new Error('missing deviation')
    }
    yield put(Actions.editResp(editedDeviation))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editErr(err))
  }
}

export function* deleteDeviation(
  client: DeviationServicePromiseClient,
  action: ReturnType<typeof Actions.deleteReq>,
) {
  try {
    const { deviationId } = action.payload
    const req = new deviationv1.DeleteDeviationRequest()
    req.setDeviationId(deviationId)
    const resp: deviationv1.DeleteDeviationResponse = yield call(
      [client, client.deleteDeviation],
      req,
      authMetadata(),
    )
    if (!resp || !resp.getDeviationId()) {
      throw new Error('missing devition ID')
    }
    yield put(Actions.deleteResp(resp.getDeviationId()))
    yield put(
      NotificationActions.send({
        key: `devition-deleted-${deviationId}`,
        kind: 'success',
        message: 'Devition deleted',
        description: `The Devition has been deleted.`,
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.deleteErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_REQ, list, clients.deviation)
  yield takeEvery(CREATE_REQ, create, clients.deviation)
  yield takeEvery(EDIT_REQ, edit, clients.deviation)
  yield takeEvery(DELETE_REQ, deleteDeviation, clients.deviation)
}
